/* Sidebar.css */
.sidebar {
    position: fixed;
    top: 25px; /* Align with zoom toolbar */
    left: 10px;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 3D shadow effect */
    z-index: 1000;
    border-radius: 5px;
    overflow: visible;
    transition: all 0.3s ease;
    width: 34px; /* Collapsed width matching zoom buttons */
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .sidebar-btn {
    width: 100%;
    height: 34px; /* Match the height of the zoom buttons */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-bottom: 1px solid #ddd;
    transition: background 0.3s ease;
    position: relative;
  }
  
  .sidebar-btn:hover {
    background: #e0e0e0;
  }
  
  .fa-icon {
    font-size: 16px; /* Adjust icon size to match zoom buttons */
    color: #666; /* Lighten the color to match the zoom buttons */
  }
  
  .sidebar-btn.active {
    background: #e0e0e0; /* Highlight the active button */
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .sidebar {
      top: 25px;
      left: 10px;
      margin-top: 0;
      width: 30px; /* Slightly smaller for mobile */
    }
  
    .sidebar-btn {
      height: 30px; /* Slightly smaller for mobile */
    }
  
    .fa-icon {
      font-size: 14px; /* Slightly smaller for mobile */
    }
  }
  
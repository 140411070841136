/* LoadingOverlay.css */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  
  .loader .letter {
    font-size: 48px;
    font-weight: bold;
    margin: 0 5px;
    animation: bounce 1s infinite;
  }
  
  .loader .letter.blue {
    color: #0056b3; /* Blue color from page layout */
  }
  
  .loader .letter.orange {
    color: #ff9900; /* Orange color from page layout */
  }
  
  .loader .letter:nth-child(1) {
    animation-delay: 0s;
  }
  
  .loader .letter:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .loader .letter:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  .loader .letter:nth-child(4) {
    animation-delay: 0.6s;
  }
  
  .loader .letter:nth-child(5) {
    animation-delay: 0.8s;
  }
  
  @keyframes bounce {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }
  
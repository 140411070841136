/* Panel.css */
.panel {
    position: fixed;
    top: 60px;
    left: 54px;
    width: 200px;
    background: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    z-index: 1001;
    transition: transform 0.3s ease;
  }
  
  .panel.open {
    transform: translateX(0);
  }
  
  .panel-title {
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    cursor: pointer;
    background: #007bff;
    color: white;
    text-align: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
  .panel-content {
    padding: 10px;
  }
  
  .accordion {
    margin-top: 5px; /* Reduce margin */
  }
  
  .accordion-item {
    margin-bottom: 5px; /* Reduce margin */
  }
  
  .accordion-title {
    background: #e0e0e0;
    padding: 5px; /* Reduce padding */
    cursor: pointer;
    border-radius: 5px;
    text-align: center;
    font-size: 12px; /* Reduce font size */
    font-weight: bold;
  }
  
  .accordion-content {
    padding: 5px; /* Reduce padding */
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
  }
  
  .icon-container {
    display: flex;
    align-items: center;
    padding: 5px; /* Reduce padding */
    cursor: pointer;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-bottom: 5px; /* Reduce margin */
  }
  
  .icon-container:hover {
    background: #e0e0e0;
  }
  
  .icon-container i {
    margin-right: 5px; /* Reduce margin */
  }
  
  .radio-container {
    display: flex;
    align-items: center;
    padding: 5px; /* Reduce padding */
    cursor: pointer;
    margin-bottom: 5px; /* Reduce margin */
  }
  
  .radio-container input[type="radio"] {
    margin-right: 10px; /* Adjust as necessary */
  }
  
  .layer-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .layer-legend {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  
.popup {
    position: absolute;
    z-index: 20;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
    padding: 0;
    max-width: 350px;
    font-family: Arial, sans-serif;
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f0f0f0; /* Light gray background */
    border-bottom: 1px solid #ddd;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.popup-header select {
    font-size: 16px;
    margin-right: 10px;
}

.popup-close {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: red; /* Close button color */
    text-align: center;
    flex-shrink: 0; /* Do not shrink */
    width:10%;
}

.popup-close:hover {
    color: #ff6666; /* Slight hover effect for better UX */
}

.popup-content {
    padding: 15px;
}

.popup-body {
    margin-top: 10px;
}

.popup-field {
    margin-bottom: 10px;
}

.popup-field strong {
    display: block;
    margin-bottom: 5px;
}
.transport-analysis {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.transport-button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.transport-button:hover {
    background-color: #0056b3;
}

html, body, #root, .home-page {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-page {
  padding: 20px;
  margin: 0 10px; /* Added margin on the left and right sides */
}

h1 {
  text-align: center;
  margin-bottom: 40px;
  width: 100%;
}

.components-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
  align-items: stretch; /* Ensure components take up equal height */
}

.component {
  flex: 1 1 calc(33.333% - 40px); /* Default width for larger screens */
  min-width: 250px;
  max-width: 400px;
  background: rgba(0, 0, 0, 0.3); /* Increased transparency to 30% */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25); /* Increased shadow for more depth */
  cursor: pointer;
  text-align: center;
  transition: transform 0.3s ease;
  margin-bottom: 20px; /* Ensure space between components */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.component:hover {
  transform: translateY(-5px);
}

@media (max-width: 768px) {
  .component {
    flex: 1 1 80%; /* Reduced width for tablets and smaller screens */
  }
}

@media (max-width: 480px) {
  .component {
    flex: 1 1 100%; /* Full width for mobile devices */
    max-width: 90%; /* Slightly reduce the width on very small screens */
  }
}

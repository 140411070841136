#three-canvas {
  margin: 0;
  overflow: hidden;
}

canvas {
  display: block;
}

.toolbar {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(50, 50, 50, 0.8); /* Darker background for better contrast */
  padding: 5px; /* Reduced padding to narrow the toolbar */
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Increased shadow for more depth */
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.toolbar button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px; /* Reduced padding to make the toolbar narrower */
  border-radius: 5px;
  color: white; /* Ensure icons are visible */
  font-size: 20px; /* Increase icon size */
}

.toolbar button.active {
  background-color: rgba(0, 150, 255, 0.8);
}

.scale-bar {
  position: absolute;
  bottom: 10px;
  left: 10px;
  background-color: rgba(50, 50, 50, 0.8); /* Match toolbar background */
  padding: 5px;
  border-radius: 3px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); /* Increased shadow for more depth */
  z-index: 10;
  font-family: Arial, sans-serif;
  font-size: 12px;
  color: white; /* Ensure text is visible */
}

.toolbar button.active {
  background-color: rgba(0, 150, 255, 0.8); /* Highlight when active */
}


.toolbar button:last-child {
  margin-top: 10px; /* Separate chatbot icon from the other buttons */
}




/* GeospatialApp.css */
html, body, #root, .map-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.map-container {
  height: 100vh;
  width: 100vw;
}

.geospatial-app {
  position: relative;
}

.sidebar {
  position: fixed;
  top: 60px; /* Align with the search bar and panel */
  left: 10px;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border-radius: 5px;
  overflow: visible;
  transition: all 0.3s ease;
  width: 34px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leaflet-control-zoom {
  position: relative;
  bottom: 0px;
  top: 0px;
  left: 10px; /* Align to the left side */
}

.leaflet-control-zoom a {
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  background-color: white;
  color: #007bff;
  margin-top: 5px; /* Add some spacing between buttons */
  border: 1px solid rgba(0, 0, 0, 0.2); /* Add a border to match other controls */
  background-clip: padding-box; /* Ensure background color respects border */
  box-shadow: none; /* Remove box shadow */
  border-radius: 0; /* Remove border radius */
}

.leaflet-control-zoom a:hover {
  background-color: #e0e0e0;
  color: #007bff;
}

/* Search container styles for Google-like UI */
.search-container {
  position: absolute;
  top: 15px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 400px;
  max-width: 80%;
}

.search-container form {
  display: flex;
  align-items: center;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  padding: 5px 10px;
}

.search-container input {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 24px;
  outline: none;
  font-size: 16px;
}

.search-container button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-left: 8px;
  width: 24px;
  height: 24px;
}

.search-container button:hover {
  background: none; /* Ensure no background color change on hover */
}

.search-container svg {
  width: 24px;
  height: 24px;
  fill: #007bff;
}

.search-container button:hover svg {
  fill: #0056b3;
}

.directions-popup {
  position: absolute;
  top: 60px; /* Align with the panel and search bar */
  left: 54px; /* Align with the panel */
  background: white; /* Ensure solid background color */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1001;
  width: 200px; /* Match the panel width */
  padding: 10px;
  color: black; /* Ensure text color is readable */
}

.popup-content {
  margin-bottom: 10px; /* Add margin to separate content */
}

.leaflet-touch .leaflet-control-layers, .leaflet-touch .leaflet-bar {
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-color: white; /* Add solid background color */
  background-clip: padding-box;
}

/* Panel.css */
.panel {
  position: fixed;
  top: 60px; /* Align with the search bar and sidebar */
  left: 54px; /* Add horizontal gap from the sidebar */
  width: 200px; /* Fixed width for expanded panel */
  background: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 3D shadow effect */
  padding: 10px;
  border-radius: 5px;
  z-index: 1001;
}

.panel-title {
  text-align: center; /* Align title in the center */
  padding: 5px 0; /* Reduce padding */
  margin: 0; /* Remove margin */
  font-size: 14px; /* Reduce font size */
}

.accordion {
  margin-top: 5px; /* Reduce margin */
}

.accordion-item {
  margin-bottom: 5px; /* Reduce margin */
}

.accordion-title {
  background: #e0e0e0;
  padding: 3px; /* Reduce padding */
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 3px; /* Reduce margin */
  text-align: center; /* Align title in the center */
  font-size: 12px; /* Reduce font size */
}

.accordion-content {
  padding: 3px; /* Reduce padding */
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
}

.icon-container {
  display: flex;
  align-items: center;
  padding: 3px; /* Reduce padding */
  cursor: pointer;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 3px; /* Reduce margin */
}

.icon-container:hover {
  background: #e0e0e0;
}

.fa-icon {
  margin-right: 5px; /* Reduce margin */
}

button {
  display: block;
  width: 100%;
  padding: 5px; /* Reduce padding */
  margin-top: 5px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background: #0056b3;
}

button.clear-button {
  background: #ff4d4d;
  margin-top: 10px; /* Add margin for spacing */
}

button.clear-button:hover {
  background: #ff1a1a;
}

.input-group {
  margin-bottom: 10px;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
}

.input-group input {
  width: calc(100% - 10px);
  padding: 5px;
  margin-bottom: 5px;
}

.custom-marker-green {
  filter: hue-rotate(120deg);
}

.custom-marker-red {
  filter: hue-rotate(0deg) saturate(5);
}

/* Chatbot icon styles */
.chatbot-icon {
  position: fixed;
  top: 15px; /* Adjust this value as needed */
  right: 10px; /* Position it on the right side */
  background: #007bff;
  color: white;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1001; /* Ensure it's on top */
}

.chatbot-icon i {
  font-size: 24px;
}

/* Chatbot window styles */
.chatbot {
  position: fixed;
  top: 70px; /* Adjust the top value to position below the component bar */
  right: 10px; /* Adjust to position it on the right side */
  width: 300px;
  max-height: 400px; /* Limit the height */
  background: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  z-index: 1002; /* Ensure it is on top of the map */
  transition: transform 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column; /* Ensure the flex direction is column */
}

.chatbot-header {
  background: #007bff;
  color: white;
  padding: 10px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  white-space: nowrap; /* Ensure the title stays on one line */
}

.chatbot-header button {
  background: transparent;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.chatbot-body {
  flex: 1; /* Ensure the body takes the remaining space */
  padding: 10px;
  max-height: calc(100% - 100px); /* Adjust based on the header and footer height */
  overflow-y: auto; /* Add vertical scroll */
}

.chatbot-footer {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatbot-footer input {
  width: calc(100% - 90px); /* Adjust width to make room for the button */
  padding: 8px; /* Adjust padding */
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: 14px; /* Adjust font size */
}

.chatbot-footer button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;
}

.chatbot-footer button:hover {
  background-color: #0056b3;
}

.prompts {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
}

.prompts button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  cursor: pointer;
}

.prompts button:hover {
  background-color: #0056b3;
}

.suggestions-list {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 10px; /* Add margin to separate from input */
}

.suggestions-list li {
  padding: 8px; /* Adjust padding */
  background: #e0e0e0;
  margin-bottom: 5px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background: #d0d0d0;
}

@import './LoadingOverlay.css';

/* General styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  min-height: 100vh;
  background-color: #f0f0f0;
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./AnsamLogo.PNG');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.21;
  z-index: -1;
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.app-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: orange;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* 3D effect */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.index-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
}

.index-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 10px;
}

.index-label {
  font-weight: bold;
  margin-right: 5px;
}

.index-value.up {
  color: green;
}

.index-value.down {
  color: red;
}

.welcome-message {
  margin-top: 70px; /* Below the header */
  font-size: 20px;
  color: #0056b3; /* Blue color from logo */
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.toggle-btn {
  position: fixed;
  top: 70px; /* Below the header */
  left: 10px;
  background: #0056b3; /* Blue color from logo */
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Shadow effect */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
}

.login-toggle {
  position: fixed;
  top: 70px; /* Below the header */
  right: 10px;
  background: #0056b3; /* Blue color from logo */
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Shadow effect */
  z-index: 1100;
}

.chatbot-toggle {
  position: fixed;
  top: 130px; /* Below the login icon */
  right: 10px;
  background: #ff9900; /* Orange color */
  color: white;
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Shadow effect */
  z-index: 1100;
}

.stocks-info-wrapper {
  margin-top: 120px; /* Ensure StocksInfo is below the fixed header */
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  flex: 1;
  margin-top: 20px; /* Ensure content starts after StocksInfo */
}

.left-panel {
  position: fixed;
  left: 0;
  top: 60px; /* Adjust top position to be below header */
  bottom: 50px; /* Adjust bottom position to not overlap footer */
  width: 250px;
  max-width: 70%;
  background: rgba(255, 255, 255, 0.8);
  transform: translateX(-100%);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  z-index: 1000;
  box-shadow: 5px 0 15px rgba(0, 0, 0, 0.3); /* Shadow effect */
  border-radius: 0 5px 5px 0; /* Rounded edges */
}

.left-panel.open {
  transform: translateX(0);
}

.logo {
  max-width: 80%;
  height: auto;
  margin: 20px auto;
  display: block;
}

.panel-content {
  padding: 20px;
  text-align: center;
}

.panel-inner-content {
  margin-top: 20px;
}

.footer {
  background: #0056b3; /* Blue color from logo */
  color: white;
  text-align: center;
  padding: 10px 0;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2); /* 3D effect */
}

.chatbot {
  width: 300px;
  height: 400px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); /* Shadow effect */
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 80px;
  right: 10px;
}

.chatbot-header {
  background: #ff9900; /* Orange color */
  color: white;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatbot-body {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.chatbot-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
}

.chatbot-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.chatbot-input button {
  background: #ff9900; /* Orange color */
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.chatbot-message {
  background: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  align-self: flex-start;
}

.chatbot-response {
  background: #e0e0e0;
  align-self: flex-end;
}

.login-form {
  width: 300px;
  height: 400px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); /* Shadow effect */
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 100px;
  right: 10px;
  z-index: 1200;
}

.login-header {
  background: #0056b3; /* Blue color */
  color: white;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-body {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-body input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.login-body button {
  background: #0056b3; /* Blue color */
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .chatbot, .login-form {
    width: 90%;
    height: 300px;
  }

  .chatbot-toggle, .login-toggle {
    padding: 8px;
  }

  .chatbot-header, .chatbot-body, .login-header, .login-body {
    padding: 8px;
  }

  .chatbot-message, .chatbot-input input, .chatbot-input button, .login-body input, .login-body button {
    padding: 8px;
    margin-bottom: 8px;
  }
}

.chatbot-window {
  position: absolute;
  top: 10px;
  left: 80px;
  width: 300px;
  height: 400px;
  background-color: rgba(50, 50, 50, 0.9);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 15;
  display: flex;
  flex-direction: column;
}

.chatbot-header {
  background-color: rgba(0, 150, 255, 0.8);
  padding: 10px;
  color: white;
  border-radius: 5px 5px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chatbot-header h3 {
  margin: 0;
}

.chatbot-header button {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  width:30px;
}

.chatbot-body {
  padding: 10px;
  color: white;
  flex: 1;
  overflow-y: auto;
}

.chatbot-footer {
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 10px;
  background-color: rgba(50, 50, 50, 0.9);
}

.chatbot-input {
  flex: 1;
  padding: 10px;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 14px;
}

.send-button {
  padding: 10px;
  background-color: rgba(0, 150, 255, 0.8);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  margin-top: 0px;
}

.send-button:hover {
  background-color: rgba(0, 120, 220, 0.8);
}

.suggestions-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  background-color: rgba(50, 50, 50, 0.9);
  border-radius: 5px;
}

.suggestions-list li {
  padding: 8px;
  cursor: pointer;
  color: black;
}

.suggestions-list li:hover {
  background-color: rgba(0, 150, 255, 0.8);
}

.chatbot-response {
  margin-bottom: 10px;
  color: rgb(19, 19, 21);
}
